import React from 'react'
import styled from 'styled-components'

import logo from '../assets/img/logo.png'
import email from '../assets/img/email.png'
import facebook from '../assets/img/facebook-square-blue.svg'
import twitter from '../assets/img/twitter-circle-blue.svg'
import whatsapp from '../assets/img/whatsapp.svg'
import colors from '../assets/js/colors'

const WebSharer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  transform: translateY(120px);
  transition: all 200ms ease-in;
  z-index: 1001;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  box-shadow: 0px 0px 4px 2px rgba( 0, 0, 0, 0.2 );
  padding: .5rem;

  &.show {
    transform: translateY(0);
  }
`

const StyledFooter = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${colors.background};
`

const PoweredBy = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 0.5rem;
  font-size: 0.75rem;
  color: ${colors.text};
  text-decoration: none;

  img {
    margin-right: 0.5rem;
    height: 25px;
    width: 25px;
  }
`

const ShareButton = styled.a`
  font-size: 0.75rem;
  margin-right: 0.5rem;
  text-decoration: none;
  text-align: right;
  color: ${colors.white};
  padding: 0.5rem;
  background-color: ${colors.primary};
  border-radius: 4px;
  cursor: pointer;
`

const Link = styled.a.attrs({
  target: '_blank',
  rel: 'noreferrer'
})`
  cursor: pointer;
  text-decoration: none;
  text-align: center;
`

const Close = styled(Link)`
  padding-bottom: .5rem;
`

const Image = styled.img`
  height: 3rem;
`

const Buttons = styled.div`
  display: flex;
  justify-content: space-around;
`

class Footer extends React.PureComponent {
  state = {
    share: false,
    url: ''
  }

  _showShareScreen = (title, text, hashtag) => {
    if (navigator.share) {
      let withHashTag = text
      if (withHashTag) withHashTag = text + ' #' + hashtag
      navigator.share({ title: title, text: withHashTag, url: this._getUrl() })
    } else {
      this.setState({ share: true })
    }
  }

  _hideShareScreen = () => {
    this.setState({ share: false })
  }

  _getUrl = () => {
    return this.state.url
  }

  _renderWebSharer = (className, title, text, hashtag) => (
    <WebSharer className={className}>
      <Close onClick={this._hideShareScreen}>Close</Close>
      <Buttons>
        <Link href={`mailto:?Subject='${title}&Body=${text} - ${this._getUrl()}`}>
          <Image src={email} alt='Email share' />
        </Link>
        <Link href={`whatsapp://send?text=${title} - ${text}`}>
          <Image src={whatsapp} alt='whatsapp share' />
        </Link>
        <Link href={`https://twitter.com/intent/tweet?url='${this._getUrl()}&text=${title} - ${text}&hashtags=${hashtag}`}>
          <Image src={twitter} alt='Twitter share' />
        </Link>
        <Link href={`http://www.facebook.com/sharer.php?u=${this._getUrl()}`}>
          <Image src={facebook} alt='Facebook share' />
        </Link>
      </Buttons>
    </WebSharer>
  )

  componentDidMount () {
    if (typeof document !== 'undefined') {
      const canonicalElement = document.querySelector('link[rel=canonical]')
      this.setState({ url: canonicalElement !== null ? canonicalElement.href : document.location.href })
    }
  }

  render () {
    const { title = 'Awesome Landing Page', text = 'Tell the world about your business!', hashtag = 'dotdotdata' } = this.props
    return (
      <StyledFooter>
        <PoweredBy href='https://www.dotdotdata.com'>
          <Image src={logo} alt='dot dot data logo' />
          <span>Powered by Dot Dot Data</span>
        </PoweredBy>
        <ShareButton onClick={() => this._showShareScreen('Form Template', 'Short description of your product will be found here.', 'dotdotdata')}>Share this page!</ShareButton>
        {this._renderWebSharer(this.state.share ? 'show' : 'hide', title, text, hashtag)}
      </StyledFooter>
    )
  }
}

export default Footer

import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import get from 'lodash/get'
import { FaFacebook, FaInstagram, FaLinkedin, FaSnapchat, FaTwitter } from 'react-icons/fa'

import Layout from '../components/Layout'

const HEIGHT = '480px'
const WIDTH = '320px'
const BORDER_RADIUS = '10px'

const Wrapper = styled.div`
  @import url(https://fonts.googleapis.com/css?family=Roboto);

  font-family: "Roboto", sans-serif;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
  * {
    outline: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  }
`

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
`

const FlipContainer = styled.div`
  cursor: pointer;
  margin: 0 auto;
  -webkit-perspective: 1000;
  -o-perspective: 1000;
  perspective: 1000;
  border-radius: ${BORDER_RADIUS};
  width: ${WIDTH};
  height: ${HEIGHT};
  
  &.flip .flipper {
    -webkit-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
  }
`

const Flipper = styled.div`
  transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  position: relative;
`

const Front = styled.div`
  background: #e86161;
  border: 1px solid #dbdbdb;
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fafafa;
  border-radius: ${BORDER_RADIUS};
  width: ${WIDTH};
  height: ${HEIGHT};
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: -1; // firefox backface-visibility fix
  position: absolute;
  top: 0;
  left: 0;
    
  img {
    max-height: 250px;
    max-width: 250px;
  }
`

const Back = styled.div`
  background: #fff;
  border: 1px solid #dbdbdb;
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.15);
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  color: #555;
  border-radius: ${BORDER_RADIUS};
  width: ${WIDTH};
  height: ${HEIGHT};
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
    
  img {
    border: 7px solid rgba(255, 255, 255, 0.5);
    width: 150px;
    border-radius: 50%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1.5rem;
  }
  
  h1 {
    margin-bottom: 0;
    margin-left: 1rem;
  }
  
  h2 {
    margin-top: 0.5rem;
    font-size: 1.25rem;
    margin-left: 1rem;
  }
`

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  text-decoration: none;
`

const HorizontalList = styled(List)`
  display: flex;
  justify-content: center;
  padding: 1rem;
  font-size: 1.2rem;
  margin-top: 1rem;
`

const Link = styled.a.attrs({
  target: '_blank'
})`
  color: #555;
  text-decoration: none;
`

const SocialLink = styled(Link)`
  padding: 1rem;
`

const Item = styled.li`
  padding: .5rem 1rem;
`

const Help = styled.div`
  color: #a1a1a1;
  text-align: center;
`

const isLink = node => node.tagName === 'path' || node.tagName === 'A' || node.parentElement.tagName === 'path' || node.parentElement.tagName === 'A'

const onTouchStart = e => {
  if (isLink(e.target)) return // do not flip on link click
  document.getElementById('flip-container').classList.toggle('hover')
}

const onClick = e => {
  if (isLink(e.target)) return // do not flip on link click
  document.getElementById('flip-container').classList.toggle('flip')
}

const FlippingCard = ({ data: { markdownRemark: { frontmatter } } }) =>
  <Layout frontmatter={frontmatter}>
    <Wrapper>

      <Container>
        <FlipContainer id='flip-container' onTouchStart={onTouchStart} onClick={onClick}>
          <Flipper className='flipper'>

            <Front>
              <img src={get(frontmatter, 'frontImage.publicURL')} alt='business card logo' />
              {frontmatter.frontHeader && <h1>{frontmatter.frontHeader}</h1>}
            </Front>

            <Back>
              <img src={get(frontmatter, 'backImage.publicURL')} alt='business card person' />
              {frontmatter.backName && <h1>{frontmatter.backName}</h1>}
              {frontmatter.backTitle && <h2>{frontmatter.backTitle}</h2>}
              <List>
                {frontmatter.backWebsite && <Item><Link href={`http://${frontmatter.backWebsite}`}>{frontmatter.backWebsite}</Link></Item>}
                {frontmatter.backEmail && <Item><Link href={`mailto:${frontmatter.backEmail}?Subject=Hello%20from%20from%20my%20virtual%20business%20card`}>{frontmatter.backEmail}</Link></Item>}
              </List>
              <HorizontalList>
                {frontmatter.facebookLink && <li><SocialLink href={frontmatter.facebookLink}><FaFacebook /></SocialLink></li>}
                {frontmatter.twitterLink && <li><SocialLink href={frontmatter.twitterLink}><FaTwitter /></SocialLink></li>}
                {frontmatter.linkedinLink && <li><SocialLink href={frontmatter.linkedinLink}><FaLinkedin /></SocialLink></li>}
                {frontmatter.snapchatLink && <li><SocialLink href={frontmatter.snapchatLink}><FaSnapchat /></SocialLink></li>}
                {frontmatter.instagramLink && <li><SocialLink href={frontmatter.instagramLink}><FaInstagram /></SocialLink></li>}
              </HorizontalList>
            </Back>

          </Flipper>
        </FlipContainer>
      </Container>

      <Help id='attribution'>
        <p>Tap card to flip it</p>
      </Help>

    </Wrapper>
  </Layout>

export default FlippingCard

export const pageQuery = graphql`
  query FlippingCardBySlug($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      id
      html
      frontmatter {
        templateKey
        title
        description
        frontImage {
          publicURL
        }
        frontHeader
        backImage {
          publicURL
        }
        backName
        backTitle
        backWebsite
        backEmail
        facebookLink
        snapchatLink
        linkedinLink
        instagramLink
        twitterLink
        favicon {
          publicURL
        }
      }
    }
  }
`
